export const DYSFUNCTIONS_TOOLS = {
  neck: { color: '#D22C2B', title: 'bodyZone.neck' },
  face: { color: '#D22C2B', title: 'bodyZone.face' },
  abdomen: { color: '#D22C2B', title: 'bodyZone.abdomen' },
  upperBody: { color: '#D22C2B', title: 'bodyZone.upperBody' },
  depressed: { color: '#E7948A', title: 'scarsAppearance.depressed' },
  raised: { color: '#E7948A', title: 'scarsAppearance.raised' },
  papules: {
    color: '#027A54',
    title: 'pimpleType.papules',
    hintText: 'info.pimpleType.papules'
  },
  pustules: {
    color: '#027A54',
    title: 'pimpleType.pustules',
    hintText: 'info.pimpleType.pustules'
  },
  nodules: {
    color: '#027A54',
    title: 'pimpleType.nodules',
    hintText: 'info.pimpleType.nodules'
  },
  irregularAndLarge: { color: '#391500', title: 'hyperpigmentationSpotShapes.irregularAndLarge' },
  roundAndSmall: { color: '#391500', title: 'hyperpigmentationSpotShapes.roundAndSmall' },
  small: { color: '#027A54', title: 'pimpleCoverage.small' },
  medium: { color: '#027A54', title: 'pimpleCoverage.medium' },
  large: { color: '#027A54', title: 'pimpleCoverage.large' }
};

export default { DYSFUNCTIONS_TOOLS };
