<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['hyperpigmentation'])">
      <single-answer-question
        title="label.hyperpigmentationToSoften"
        :value="hyperpigmentation"
        :options="hyperPigmentationResponseOptions"
        dysfunction="skin-pigmentation"
        yes-no
        @input="onFieldChange('hyperpigmentation', $event)"
      />
    </question-with-error-wrap>

    <question-divider
      v-if="isQuestionDividerVisible"
      class="q-mt24 q-mb32 q-mt32-md q-mb40-md"
      title="questionnaire.tellUsMore.title"
    />

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="hasHyperPigmentation"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['spotShapes'])"
      >
        <single-picture-select
          id="spot-shapes"
          title="label.hyperpigmentationSpotShapes"
          :value="spotShapes"
          :options="spotShapesOptions"
          @input="onFieldChange('spotShapes', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import QuestionDivider from '@/modules/questionnaire/components/common/QuestionDivider';
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import SinglePictureSelect from '@/modules/questionnaire/new-design-components/questions/SinglePictureSelect';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { flagAiSelectedOption } from '@/modules/questionnaire/api/helpers';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';
import { SPOT_SHAPE_OPTIONS } from '@/modules/questionnaire/constants/steps/skinPigmentation';

export default {
  name: 'PigmentationTemplate',
  components: {
    FadeInTransitionWrap,
    QuestionDivider,
    QuestionWithErrorWrap,
    SingleAnswerQuestion,
    SinglePictureSelect
  },
  mixins: [stepTemplateMixin],
  props: {
    hasHyperPigmentation: {
      type: Boolean,
      default: false
    },
    hyperpigmentation: {
      type: String,
      default: ''
    },
    hyperpigmentationAiSelected: {
      type: String,
      default: ''
    },
    spotShapes: {
      type: String,
      default: ''
    },
    spotShapesAiSelected: {
      type: String,
      default: ''
    }
  },
  computed: {
    hyperPigmentationResponseOptions() {
      return flagAiSelectedOption(YES_NO_RESPONSE_OPTIONS, this.hyperpigmentationAiSelected);
    },
    spotShapesOptions() {
      return flagAiSelectedOption(SPOT_SHAPE_OPTIONS, this.spotShapesAiSelected);
    },
    isQuestionDividerVisible() {
      return this.hasHyperPigmentation && !this.spotShapesAiSelected;
    }
  }
};
</script>
