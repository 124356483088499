<template>
  <div class="single-picture-select">
    <question-title :hint="hint" class="q-mb16 q-mt0">{{ $t(title) }}</question-title>
    <div
      class="single-picture-select__options single-select-options flex flex-wrap--wrap flex--space-between--center"
      role="radiogroup"
      tabindex="-1"
      :aria-label="$t(title)"
      data-question
      @keydown.down.prevent="onKeyboardNavigation('down')"
      @keydown.up.prevent="onKeyboardNavigation('up')"
    >
      <template v-for="option of options">
        <picture-select
          ref="option"
          :key="option.value"
          class="single-select-options__option q-mb24"
          :class="pictureSelectClasses"
          :value="option.value"
          :gender="gender"
          :ai-selected="option.aiSelected"
          :active="getActiveValue(option.value)"
          role="radio"
          :tabindex="getOptionTabIndex(option)"
          @input="$emit('input', $event)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { clamp } from 'ramda';

import PictureSelect from '@/modules/questionnaire/new-design-components/inputs/PictureSelect.vue';
import QuestionTitle from '@/modules/questionnaire/new-design-components/questions/QuestionTitle';

export default {
  name: 'SinglePictureSelect',
  components: { PictureSelect, QuestionTitle },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    gender: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    dysfunction: {
      type: String,
      default: ''
    }
  },
  computed: {
    pictureSelectClasses() {
      return {
        'single-select-options__option--three-elements-per-row': this.options.length === 3
      };
    },
    selectedOptionIndex() {
      return this.options.findIndex(option => option.value === this.value);
    }
  },
  methods: {
    getActiveValue(value) {
      return this.value === value ? value : '';
    },
    onKeyboardNavigation(direction) {
      const increment = direction === 'down' ? 1 : -1;
      const index = clamp(0, this.options.length - 1, this.selectedOptionIndex + increment);

      this.$refs.option[index].$el.focus();
    },
    getOptionTabIndex(option) {
      if (!this.value) {
        return '0';
      }

      return option.value === this.value ? '0' : '-1';
    }
  }
};
</script>

<style lang="scss" scoped>
.single-picture-select {
  &__options {
    flex-direction: row;
    align-items: stretch;
  }
}

.single-select-options {
  &__option {
    flex: 1 1 45%;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    &--three-elements-per-row {
      flex: 1 1 30%;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
</style>
