<template>
  <div
    class="picture-select"
    :class="valueClasses"
    :role="role"
    :tabindex="tabindex"
    :aria-checked="isActive ? 'true' : 'false'"
    @click="onInput"
    @keydown.space="onSpaceKeyPress"
    @focus="onFocus"
  >
    <ai-selected-tag v-if="aiSelected" class="picture-select__ai-selected-tag" />
    <div class="picture-select__inner">
      <info-tooltip
        v-if="valueParameters.hintText"
        class="q-mb16"
        :hint="valueParameters.hintText"
      />

      <img class="picture-select__image" :src="pictureSrc" alt="" />
      <div class="picture-select__title q-mt16">{{ $t(title) }}</div>

      <u-checkbox
        v-if="role === 'checkbox'"
        class="picture-select__checkbox q-mt8"
        :value="isActive"
        :color="dysfunction"
        :input-attrs="{ tabindex: '-1' }"
        @click.native.prevent
      />
    </div>
  </div>
</template>

<script>
import { camelCase, kebabCase } from 'lodash';
import { UCheckbox } from 'universkin-shared';

import InfoTooltip from '@/modules/questionnaire/new-design-components/questions/InfoTooltip';
import AiSelectedTag from '@/modules/questionnaire/new-design-components/inputs/AiSelectedTag';

import { DYSFUNCTIONS_TOOLS } from '@/modules/questionnaire/constants/dysfunctionTips';
import { SPOT_SHAPE } from '@/modules/questionnaire/api/constants';

/* TODO: Refactor the component https://jira.andersenlab.com/browse/UNK-6337 */
export default {
  name: 'PictureSelect',
  components: { InfoTooltip, UCheckbox, AiSelectedTag },
  model: {
    prop: 'active',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    gender: {
      type: String,
      default: ''
    },
    active: {
      type: String,
      required: true
    },
    aiSelected: {
      type: Boolean,
      default: false
    },
    role: {
      type: String,
      required: true
    },
    dysfunction: {
      type: String,
      default: ''
    },
    tabindex: {
      type: String,
      default: '0'
    }
  },
  computed: {
    isActive() {
      return this.value === this.active;
    },
    isPimples() {
      return ['small', 'medium', 'large'].includes(this.value);
    },
    isSpotShape() {
      return [SPOT_SHAPE.ROUND_AND_SMALL, SPOT_SHAPE.IRREGULAR_AND_LARGE].includes(this.value);
    },
    pictureSrc() {
      if (this.isPimples) {
        return require(`@/modules/questionnaire/assets/images/picture-select/pimples/${this.value}.svg`);
      }

      if (this.isSpotShape) {
        return require(`@/modules/questionnaire/assets/images/picture-select/spot-shape/${this.value}.svg`);
      }

      const fileName = `${camelCase(this.value)}.svg`;

      if (!this.gender) {
        return require(`@/modules/questionnaire/assets/images/dysfunctions/${fileName}`);
      }

      if (this.isActive) {
        return require(`@/modules/questionnaire/assets/images/gender/${this.gender}/active/${fileName}`);
      }

      return require(`@/modules/questionnaire/assets/images/gender/${this.gender}/non-active/${fileName}`);
    },
    valueClasses() {
      const valueModifier = kebabCase(this.value);

      return {
        'picture-select--active': this.isActive,
        'picture-select--pimples': this.isPimples,
        [`picture-select--${this.gender}`]: this.gender,
        [`picture-select--${valueModifier}`]: !this.gender
      };
    },
    valueParameters() {
      const key = camelCase(this.value);

      return DYSFUNCTIONS_TOOLS[key];
    },
    title() {
      return this.valueParameters.title;
    }
  },
  methods: {
    onInput() {
      if (this.role === 'radio') {
        this.$emit('input', this.value);

        return;
      }

      if (this.isActive) {
        this.$emit('input', '');
      } else {
        this.$emit('input', this.value);
      }
    },
    onSpaceKeyPress() {
      if (this.role !== 'checkbox') {
        return;
      }

      this.onInput();
    },
    onFocus() {
      if (this.role !== 'radio') {
        return;
      }

      this.onInput();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors.scss';
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.picture-select {
  min-height: 181px;
  padding: 12px 0 16px;

  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;

  border: 1px solid $color-grey200;
  border-radius: 10px;
  cursor: pointer;

  --border-active-color: #{$color-grey400};
  --background-active-color: transparent;

  &--active,
  &:hover {
    border-color: var(--border-active-color);
    background-color: var(--background-active-color);
  }

  &--male,
  &--female,
  &--other {
    --border-active-color: #{$color-skin-redness};
  }

  &--depressed,
  &--raised {
    --border-active-color: #{$color-skin-appearance};
    --background-active-color: #{$color-skin-appearance-light};
  }

  &--papules,
  &--nodules,
  &--pustules {
    --border-active-color: #{$color-pimples};
    --background-active-color: #{$color-pimples-light};
  }

  &--irregular-and-large,
  &--round-and-small {
    --border-active-color: #{$color-skin-pigmentation};
    --background-active-color: #{$color-skin-pigmentation-light};
  }

  &--pimples {
    --border-active-color: #{$color-pimples};
    --background-active-color: #{$color-pimples-light};
  }

  &__ai-selected-tag {
    position: absolute;
    top: 0;
    left: 23px;
    transform: translateY(-50%);
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    text-align: center;
  }

  &__title {
    width: 100%;

    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-family: var(--u-font-family-Gilroy);
  }

  &__checkbox {
    margin-left: 12px;
  }
}
</style>
